import { Action, Dispatch } from 'redux';

export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';

export interface ToggleSidebarAction extends Action<typeof TOGGLE_SIDEBAR> {
    id: string;
}

const toggleSidebar = (id: string): ToggleSidebarAction => ({
    type: TOGGLE_SIDEBAR,
    id
});

export const ToggleSidebarActionCreator = {
    toggleSidebar: (id: string) => {
        return (dispatch: Dispatch<ToggleSidebarAction>, getState: any): void => {
            dispatch(toggleSidebar(id));
        };
    }
};

export interface ToggleSidebarActionProps {
    toggleSidebar(id: string): void;
}
