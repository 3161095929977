import React, {CSSProperties, FunctionComponent} from 'react';
import {Nav} from 'react-bootstrap';
import {connect} from 'react-redux';
import {SettingsProps} from '../interfaces/SettingsProps';
import {getSidebarBackgroundColorStyle} from '../helpers/settingsHelpers';
import {ToggleSidebarActionCreator, ToggleSidebarActionProps} from '../actions/SidebarActionCreator';
import {bindActionCreators} from 'redux';
import classNames from 'classnames';
import {SidebarState} from "../reducers/sidebarReducer";

export interface SidebarProps {
    id: string;
    style?: CSSProperties;
    position?: 'left' | 'right';
}

type SidebarStateProps = SettingsProps & SidebarState;

const Sidebar: FunctionComponent<SidebarProps & SidebarStateProps & ToggleSidebarActionProps> = (props) => {
    const sidebarStyle = { ...getSidebarBackgroundColorStyle(props), ...(props.style || {}) };

    return (
        <React.Fragment>
            <Nav
                className={classNames('sidebar', { hidden: !props.sidebarExpanded?.includes(props.id) }, props.position || 'left')} 
                style={sidebarStyle} 
                id={props.id}
            >
                {props.children}
            </Nav>
            <div className={classNames('overlay', { hidden: !props.sidebarExpanded?.includes(props.id) })} onClick={() => props.toggleSidebar(props.id)} />
        </React.Fragment>
    );
};

const mapStateToProps = (state: any) => ({
    ...state.settingsActionsReducer,
    ...state.sidebarReducer
});

const mapActionCreatorsToProps = (dispatch: any) => bindActionCreators(ToggleSidebarActionCreator, dispatch);

export default connect<SidebarStateProps, ToggleSidebarActionProps, SidebarProps, any>(mapStateToProps, mapActionCreatorsToProps)(Sidebar);
