import React, { FunctionComponent, useMemo } from 'react';
import { BooleanInputField } from '../../../common/components/input-fields/BooleanInputField';
import TextInputField from '../../../common/components/input-fields/TextInputField';
import { Translate, withLocalize, LocalizeContextProps } from 'react-localize-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExchangeAlt, faTimesCircle, faAddressBook } from '@fortawesome/free-solid-svg-icons';
import NumberInputField, { NumberInputFieldKind } from '../../../common/components/input-fields/NumberInputField';
import DateTimeInputField, { DateTimeInputFieldKind } from '../../../common/components/input-fields/DateTimeInputField';
import DropdownInputField from '../../../common/components/input-fields/DropdownInputField';
import { getCivilStatuses } from '../../../common/helpers/getCivilStatuses';
import { getCivilStatusTranslateKey } from '../../../common/helpers/getCivilStatusTranslateKey';
import { getDwellings } from '../../../common/helpers/getDwellings';
import { getDwellingTranslateKey } from '../../../common/helpers/getDwellingTranslateKey';
import { ConsumerLoanApplicantDetails } from '../models/ConsumerLoanApplicantDetails';
import { RepaymentMethod } from '../../../common/models/RepaymentMethod';
import './applicant-box.css';
import { getJobStatuses } from '../../../common/helpers/getJobStatuses';
import { getJobStatusTranslateKey } from '../helpers/getJobStatusTranslateKey';
import { JobStatus } from '../models/JobStatus';
import DanishApplicantFields from './DanishApplicantFields';
import FinnishApplicantFields from './FinnishApplicantFields';
import ActionIcon from '../../../common/components/ActionIcon';
import { LanguageItem, getLanguageItems } from '../../../common/helpers/getLanguageItems';

export interface ApplicantBoxProps extends LocalizeContextProps {
    applicant: ConsumerLoanApplicantDetails;
    countryId: number;
    isCoApplicant?: boolean;
    errors: string[];
    isComplete: boolean;
    onDelete?: () => void;
    onApplicantsSwapped?: () => void;
    onApplicantChanged: (applicant: ConsumerLoanApplicantDetails) => void;
}

const ApplicantBox: FunctionComponent<ApplicantBoxProps> = (props: ApplicantBoxProps) => {
    const civilStatuses = useMemo(() => getCivilStatuses().map((value) => ({ name: getCivilStatusTranslateKey(value), value })), []);
    const dwellings = useMemo(() => getDwellings().map((value) => ({ name: getDwellingTranslateKey(value), value })), []);
    const jobStatuses = useMemo(() => getJobStatuses().map((value) => ({ name: getJobStatusTranslateKey(value), value })), []); // eslint-disable-line react-hooks/exhaustive-deps
    const languages = useMemo(() => getLanguageItems(props.translate, false, false), []); // eslint-disable-line react-hooks/exhaustive-deps
    const title = props.isCoApplicant ? 'COAPPLICANT' : 'APPLICANT';
    const errorPrefix = props.isCoApplicant ? 'InvalidCoApplicant ' : 'InvalidApplicant ';
    const errors = props.errors
        .filter((e) => e.startsWith(errorPrefix) || e === 'ApplicantAndCoApplicantHaveEqualSocialSecurityNumber')
        .map((e) => e.replace(errorPrefix, ''));
    const getItemValue = (item: { name: string, value: string | RepaymentMethod | JobStatus }): string | RepaymentMethod | JobStatus => (item.value);

    const getItemDisplayValue = (item: { name: string }): string => (props.translate(item.name).toString());

    const onValueChanged = (value: string | number | boolean | Date | null | undefined, name: string): void => props.onApplicantChanged({ ...props.applicant, [name]: value });

    const renderDanishApplicantFields = () => props.countryId === 2 ? (
        <DanishApplicantFields
            applicant={props.applicant}
            countryId={props.countryId}
            isCoApplicant={props.isCoApplicant}
            isComplete={props.isComplete}
            errors={errors}
            onApplicantChanged={props.onApplicantChanged}
            onApplicantsSwapped={props.onApplicantsSwapped}
            onDelete={props.onDelete}
        />
    ) : null;

    const renderFinnishApplicantFields = () => props.countryId === 3 ? (
        <FinnishApplicantFields
            applicant={props.applicant}
            countryId={props.countryId}
            isCoApplicant={props.isCoApplicant}
            isComplete={props.isComplete}
            errors={errors}
            onApplicantChanged={props.onApplicantChanged}
            onApplicantsSwapped={props.onApplicantsSwapped}
            onDelete={props.onDelete}
        />
    ) : null;

    return (
        <div className="column-card">
            <div className="card-heading">
                <span className="title">
                    <Translate id={title} />
                </span>
                <span className="options">
                    {renderIcons(props)}
                </span>
            </div>
            <div className="card-content">
                {renderCommonFields(props, onValueChanged, getItemValue, getItemDisplayValue, dwellings, civilStatuses, jobStatuses, languages, errors)}
                {renderDanishApplicantFields()}
                {renderFinnishApplicantFields()}
            </div>
        </div>
    );
};

function renderIcons(props: ApplicantBoxProps) {
    if (props.isCoApplicant !== undefined && props.isCoApplicant === true) {
        return (
            <React.Fragment>
                <ActionIcon action={props.onApplicantsSwapped} icon={faExchangeAlt} tooltipKey="SWITCH_APPLICANTS" />
                <ActionIcon action={props.onDelete} icon={faTimesCircle} tooltipKey="DELETE_COAPPLICANT" />
                <span><FontAwesomeIcon icon={faAddressBook} /></span>
            </React.Fragment>
        );
    }

    return <FontAwesomeIcon icon={faAddressBook} />;
}

function renderCoApplicantFields(
    props: ApplicantBoxProps & LocalizeContextProps,
    onValueChanged: (value: string | number | boolean | Date | null, name: string) => void) {
    return props.isCoApplicant === true ? (
        <BooleanInputField
            descriptionKey="LIVING_AT_APPLICANT_ADDRESS"
            editMode={true}
            name="livingAtApplicantAddress"
            onValueChanged={onValueChanged}
            value={props.applicant.livingAtApplicantAddress}
        />
    ) : null;
}

function renderAddressFields(props: ApplicantBoxProps, errors: string[], onValueChanged: (value: string | number | boolean | Date | null, name: string) => void) {

    if (props.isCoApplicant === true && props.applicant.livingAtApplicantAddress === true) {
        return null;
    }

    return (
        <React.Fragment>
            <TextInputField
                descriptionKey="STREET_ADDRESS"
                editMode={true}
                disabled={props.isCoApplicant === true && props.applicant.livingAtApplicantAddress === true}
                name="streetAddress"
                errors={errors}
                onValueChanged={onValueChanged}
                value={props.applicant.streetAddress}
            />
            <div className="half-sized-inputs-container">
                <div>
                    <TextInputField
                        descriptionKey="POSTAL_CODE"
                        editMode={true}
                        disabled={props.isCoApplicant === true && props.applicant.livingAtApplicantAddress === true}
                        name="postalCode"
                        errors={errors}
                        errorCodes={['InvalidPostalCodeFormat']}
                        onValueChanged={onValueChanged}
                        value={props.applicant.postalCode}
                    />
                </div>
                <div>
                    <TextInputField
                        descriptionKey="CITY"
                        editMode={true}
                        disabled={props.isCoApplicant === true && props.applicant.livingAtApplicantAddress === true}
                        name="city"
                        errors={errors}
                        onValueChanged={onValueChanged}
                        value={props.applicant.city}
                    />
                </div>
            </div>
        </React.Fragment>
    );
}

function renderCommonFields(
    props: ApplicantBoxProps & LocalizeContextProps,
    onValueChanged: (value: string | number | boolean | Date | null | undefined, name: string) => void,
    getItemValue: (item: { name: string, value: string | RepaymentMethod | JobStatus }) => string | RepaymentMethod | JobStatus,
    getItemDisplayValue: (item: { name: string }) => string,
    dwellings: Array<{ name: string, value: string }>,
    civilStatuses: Array<{ name: string, value: string }>,
    jobStatuses: Array<{ name: string, value: JobStatus }>,
    languages: LanguageItem[],
    errors: string[]): JSX.Element {
    return (
        <>
            <div className="half-sized-inputs-container">
                <div>
                    <TextInputField
                        descriptionKey="FIRST_NAME"
                        editMode={true}
                        name="firstName"
                        errors={errors}
                        onValueChanged={onValueChanged}
                        value={props.applicant.firstName}
                    />
                </div>
                <div>
                    <TextInputField
                        descriptionKey="LAST_NAME"
                        editMode={true}
                        name="lastName"
                        errors={errors}
                        onValueChanged={onValueChanged}
                        value={props.applicant.lastName}
                    />
                </div>
            </div>
            <div className="half-sized-inputs-container">
                <div>
                    <TextInputField
                        descriptionKey="SOCIAL_SECURITY_NUMBER"
                        editMode={true}
                        name="socialSecurityNumber"
                        required="SocialSecurityNumberIsRequired"
                        errors={errors}
                        errorCodes={['InvalidSocialSecurityNumberFormat', 'ApplicantAndCoApplicantHaveEqualSocialSecurityNumber']}
                        overrideInternalErrors={['valueMissing']}
                        onValueChanged={onValueChanged}
                        value={props.applicant.socialSecurityNumber}
                    />
                </div>
                <div>
                    <BooleanInputField
                        descriptionKey="CITIZENSHIP"
                        editMode={true}
                        name="citizenship"
                        onValueChanged={onValueChanged}
                        value={props.applicant.citizenship}
                        required="CitizenshipIsRequired"
                        errors={errors}
                        overrideInternalErrors={['valueMissing']}
                    />
                </div>
            </div>
            {renderCoApplicantFields(props, onValueChanged)}
            {renderAddressFields(props, errors, onValueChanged)}
            <TextInputField
                descriptionKey="EMAIL"
                editMode={true}
                name="email"
                errors={errors}
                errorCodes={['InvalidEmailFormat', 'CannotUseTelefinansEmail', 'EmailOrMobileNumberIsRequired', 'EmailIsRequired']}
                overrideInternalErrors={['valueMissing']}
                onValueChanged={onValueChanged}
                value={props.applicant.email}
            />
            <TextInputField
                descriptionKey="ADDITIONAL_EMAIL"
                editMode={true}
                name="additionalEmail"
                errors={errors}
                errorCodes={['InvalidAdditionalEmailFormat']}
                onValueChanged={onValueChanged}
                value={props.applicant.additionalEmail}
            />
            <TextInputField
                descriptionKey="MOBILE_NUMBER"
                editMode={true}
                name="mobileNumber"
                errors={errors}
                errorCodes={['InvalidMobileNumberFormat', 'EmailOrMobileNumberIsRequired', 'MobileNumberIsRequired']}
                overrideInternalErrors={['valueMissing']}
                onValueChanged={onValueChanged}
                value={props.applicant.mobileNumber}
            />
            <TextInputField
                descriptionKey="ADDITIONAL_MOBILE_NUMBER"
                editMode={true}
                name="additionalMobileNumber"
                errors={errors}
                errorCodes={['InvalidAdditionalMobileNumberFormat']}
                onValueChanged={onValueChanged}
                value={props.applicant.additionalMobileNumber}
            />
            <div className="divider" />
            <DropdownInputField
                descriptionKey="MARITAL_STATUS"
                editMode={true}
                name="civilStatus"
                required={props.isComplete ? 'CivilStatusIsRequired' : undefined}
                errors={errors}
                overrideInternalErrors={['valueMissing']}
                keyValue={getItemValue}
                displayValue={getItemDisplayValue}
                onValueChanged={onValueChanged}
                value={props.applicant.civilStatus}
                items={civilStatuses}
            />
            <div className="half-sized-inputs-container">
                <div>
                    <NumberInputField
                        descriptionKey="PARTNER_GROSS_YEARLY_INCOME"
                        editMode={true}
                        name="partnerGrossYearlyIncome"
                        errors={errors}
                        kind={NumberInputFieldKind.MoneyPerYear}
                        onValueChanged={onValueChanged}
                        value={props.applicant.partnerGrossYearlyIncome}
                    />
                </div>
                <div>
                    <NumberInputField
                        descriptionKey="CHILDREN_UNDER_AGE_18"
                        editMode={true}
                        name="numberOfChildren"
                        errors={errors}
                        kind={NumberInputFieldKind.NumberOf}
                        onValueChanged={onValueChanged}
                        value={props.applicant.numberOfChildren}
                    />
                </div>
            </div>
            <DropdownInputField
                descriptionKey="DWELLING"
                editMode={true}
                name="dwelling"
                required={props.isComplete ? 'DwellingIsRequired' : undefined}
                errors={errors}
                overrideInternalErrors={['valueMissing']}
                keyValue={getItemValue}
                displayValue={getItemDisplayValue}
                onValueChanged={onValueChanged}
                value={props.applicant.dwelling}
                items={dwellings}
            />
            <div className="half-sized-inputs-container">
                <div>
                    <DateTimeInputField
                        descriptionKey="ADDRESS_SINCE"
                        name="dwellingSinceDate"
                        required={props.isComplete ? 'DwellingSinceDateIsRequired' : undefined}
                        errors={errors}
                        overrideInternalErrors={['valueMissing']}
                        editMode={true}
                        onValueChanged={onValueChanged}
                        value={props.applicant.dwellingSinceDate}
                        kind={DateTimeInputFieldKind.Year}
                    />
                </div>
                <div>
                    <NumberInputField
                        descriptionKey="DWELLING_MONTHLY_RENT"
                        editMode={true}
                        name="dwellingMonthlyRent"
                        errors={errors}
                        kind={NumberInputFieldKind.MoneyPerMonth}
                        onValueChanged={onValueChanged}
                        value={props.applicant.dwellingMonthlyRent}
                    />
                </div>
            </div>
            <div className="half-sized-inputs-container">
                <div>
                    <NumberInputField
                        descriptionKey="GROSS_YEARLY_INCOME"
                        editMode={true}
                        name="grossYearlyIncome"
                        errors={errors}
                        kind={NumberInputFieldKind.MoneyPerYear}
                        onValueChanged={onValueChanged}
                        value={props.applicant.grossYearlyIncome}
                    />
                </div>
                <div>
                    <NumberInputField
                        descriptionKey="AFTER_TAX_MONTHLY_INCOME"
                        editMode={true}
                        name="afterTaxMonthlyIncome"
                        errors={errors}
                        kind={NumberInputFieldKind.MoneyPerMonth}
                        onValueChanged={onValueChanged}
                        value={props.applicant.afterTaxMonthlyIncome}
                    />
                </div>
            </div>
            <NumberInputField
                descriptionKey="OTHER_MONTHLY_INCOME"
                editMode={true}
                name="extraMonthlyIncome"
                errors={errors}
                kind={NumberInputFieldKind.MoneyPerMonth}
                onValueChanged={onValueChanged}
                value={props.applicant.extraMonthlyIncome}
            />
            <div className="half-sized-inputs-container">
                <div>
                    <DropdownInputField
                        descriptionKey="JOB_STATUS_LABEL"
                        editMode={true}
                        name="jobStatus"
                        required={props.isComplete ? 'JobStatusIsRequired' : undefined}
                        errors={errors}
                        overrideInternalErrors={['valueMissing']}
                        keyValue={getItemValue}
                        displayValue={getItemDisplayValue}
                        onValueChanged={onValueChanged}
                        value={props.applicant.jobStatus}
                        items={jobStatuses}
                    />
                </div>
                <div>
                    <BooleanInputField
                        descriptionKey="TEMPORARILY_DISCHARGED"
                        editMode={true}
                        name="temporarilyDischarged"
                        onValueChanged={onValueChanged}
                        value={props.applicant.temporarilyDischarged}
                    />
                </div>
            </div>
            <TextInputField
                descriptionKey="OCCUPATION"
                editMode={true}
                name="occupation"
                required={props.isComplete ? 'OccupationIsRequired' : undefined}
                errors={errors}
                overrideInternalErrors={['valueMissing']}
                onValueChanged={onValueChanged}
                value={props.applicant.occupation}
            />
            <TextInputField
                descriptionKey="EMPLOYER"
                editMode={true}
                name="employer"
                errors={errors}
                maxLength={100}
                onValueChanged={onValueChanged}
                value={props.applicant.employer}
            />
            <DateTimeInputField
                descriptionKey="EMPLOYMENT_DATE"
                name="employmentDate"
                required={props.isComplete ? 'EmploymentDateIsRequired' : undefined}
                errors={errors}
                overrideInternalErrors={['valueMissing']}
                editMode={true}
                onValueChanged={onValueChanged}
                value={props.applicant.employmentDate}
                kind={DateTimeInputFieldKind.YearWithMonth}
            />
            <DropdownInputField
                descriptionKey="PRIMARY_LANGUAGE"
                editMode={true}
                name="primaryLanguage"
                onValueChanged={onValueChanged}
                value={props.applicant.primaryLanguage || undefined}
                items={languages}
            />
            {props.applicant.citizenship === false &&
                <TextInputField
                    descriptionKey="NATIONALITY_OTHER"
                    editMode={true}
                    name="nationalityOther"
                    errors={props.errors}
                    errorCodes={[]}
                    onValueChanged={onValueChanged}
                    value={props.applicant.nationalityOther}
                />
            }
            {props.isCoApplicant !== true &&
                <BooleanInputField
                    descriptionKey="POLITICALLY_EXPOSED_PERSON"
                    disabled={false}
                    editMode={true}
                    name="politicallyExposedPerson"
                    onValueChanged={onValueChanged}
                    value={props.applicant.politicallyExposedPerson}
                />
            }
        </>
    );
}

export default withLocalize(ApplicantBox);
