import Sidebar from "./Sidebar";
import UserInfo from "./UserInfo";
import {FunctionComponent, useEffect, useState} from "react";
import {SettingsProps} from "../interfaces/SettingsProps";
import {connect} from "react-redux";
import {getNewApplicationTemplates} from "../helpers/settingsHelpers";
import {NewApplicationTemplate} from "../../applications/applicant/models/NewApplicationTemplate";
import DropdownInputField from "./input-fields/DropdownInputField";
import {LocalizeContextProps, withLocalize} from "react-localize-redux";

type NewApplicationTemplateItem = {name: string, value: NewApplicationTemplate | null}
export const USER_SETTINGS_SIDEBAR_ID = 'user-settings-sidebar';
const UserSettingsSidebar: FunctionComponent<SettingsProps & LocalizeContextProps> = (props) => {
    const customTemplatesItems: NewApplicationTemplateItem[] = getNewApplicationTemplates(props);
    const userTemplateItems: NewApplicationTemplateItem = {name: props.translate('USER_DEFINED').toString(), value: null};
    const [selectedTemplate, setSelectedTemplate] = useState<string>(localStorage['customNewApplicationTemplate'] || 'null');
    
    useEffect(() => {
        localStorage['customNewApplicationTemplate'] = selectedTemplate;
    }, [selectedTemplate]);
    
    return (
        <Sidebar id={USER_SETTINGS_SIDEBAR_ID} position="right">
            <UserInfo/>
            <div style={{margin: '12px'}}>
                <DropdownInputField<NewApplicationTemplateItem>
                    name="newApplicationTemplates"
                    description={props.translate('NEW_APPLICATION_TEMPLATE')}
                    editMode={true}
                    style={{background: 'white'}}
                    keyValue={item => JSON.stringify(item.value)}
                    displayValue={item => item.name}
                    value={selectedTemplate}
                    items={customTemplatesItems.concat(userTemplateItems)}
                    onValueChanged={template => setSelectedTemplate(template as string)}
                />
            </div>
        </Sidebar>
    )
}

const mapStateToProps = (state: any) => ({
    ...state.settingsActionsReducer
});
export default connect<SettingsProps, {}, {}, any>(mapStateToProps)(withLocalize(UserSettingsSidebar));