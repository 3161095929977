import {faCog} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {FunctionComponent} from "react";
import {USER_SETTINGS_SIDEBAR_ID} from "./UserSettingsSidebar";
import {ToggleSidebarActionCreator, ToggleSidebarActionProps} from "../actions/SidebarActionCreator";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

const UserSettingsButton: FunctionComponent<ToggleSidebarActionProps> = (props) => {
    return (
        <FontAwesomeIcon
            style={{cursor: 'pointer'}}
            icon={faCog} 
            onClick={() => props.toggleSidebar(USER_SETTINGS_SIDEBAR_ID)}    
        />
    )
}

const mapActionCreatorsToProps = (dispatch: any) => bindActionCreators(ToggleSidebarActionCreator, dispatch);

export default connect<{}, ToggleSidebarActionProps, {}, any>(null, mapActionCreatorsToProps)(UserSettingsButton);