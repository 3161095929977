import React, {FunctionComponent} from 'react';
import {connect} from 'react-redux';
import TopbarButtons from './TopbarButtons';
import {SettingsProps} from '../interfaces/SettingsProps';
import {getLogo, getSidebarBackgroundColorStyle, getSidebarFontColor} from '../helpers/settingsHelpers';
import UserInfo from "./UserInfo";

type TopbarProps = SettingsProps;

const Topbar: FunctionComponent<TopbarProps> = (props) => {

    const sidebarBackgroundColorStyle = {
        ...getSidebarBackgroundColorStyle(props),
        ...getSidebarFontColor(props)
    };

    return (
        <div className="topbar" style={sidebarBackgroundColorStyle}>
            <TopbarButtons logoSetting={getLogo(props)} />
            <UserInfo/>
        </div>
    );
};

const mapStateToProps = (state: any) => ({
    ...state.settingsActionsReducer
});

export default connect<TopbarProps, {}, {}, any>(mapStateToProps)(Topbar);
