import {faPaperclip, faInfoCircle, faServer} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent } from 'react';
import { BankReportHistory, ChangeBankReportHistory, PromissoryNoteBankReportHistory, StatusBankReportHistoryReadModel } from '../../models/BankReportHistory';
import Tooltip from '../../../../common/components/Tooltip';
import DateTimeFormatter from '../../../../common/components/DateTimeFormatter';
import ChangeItem from '../ChangeItem';
import { BankReportHistoryType } from '../../models/BankReportHistoryType';
import Translation from '../../../../common/components/Translation';
import { Translate } from 'react-localize-redux';
import { getPromissoryNote } from '../../api/getDocument';
import FileLink from '../../../../common/components/FileLink';
import CopyToClipboardButton from '../../../../common/components/CopyToClipboardButton';

interface BankRepliesHistoryProps {
    bankHistories: BankReportHistory[] | undefined;
}

const BankRepliesHistory: FunctionComponent<BankRepliesHistoryProps> = (props) => {
    if (!props.bankHistories || props.bankHistories.length === 0) {
        return null;
    }

    return <div className="bank-report-history"><table><tbody>{props.bankHistories.map(renderRow)}</tbody></table></div>;
};

function renderRow(history: BankReportHistory) {
    return (
        <tr key={`br-history-${history.historyType}-${history.id}`}>
            {renderIconAndContent(history as ChangeBankReportHistory)}
            <td><DateTimeFormatter date={history.timestamp}/></td>
        </tr>
    );
}

function renderIconAndContent(history: BankReportHistory) {
    switch (history.historyType) {
        case BankReportHistoryType.Change:
            return renderChange(history as ChangeBankReportHistory);
        case BankReportHistoryType.PromissoryNote:
            return renderPromissoryNote(history as PromissoryNoteBankReportHistory);
        case BankReportHistoryType.Status:
            return renderStatus(history as StatusBankReportHistoryReadModel);
    }
}

function renderChange(change: ChangeBankReportHistory) {
    switch (change.propertyName) {
        case 'IsProcessed':
            return <ChangeItem customContent={<Translate id="RESEND"/>} user={change.user}/>;
        case 'ResponseCode':
            return <ChangeItem customContent={<Translate id="RESET_ERROR"/>} user={change.user}/>;
        default:
            const propertyName = <Translation id={change.propertyName} prefix="BANK_REPORT"/>;
            return <ChangeItem propertyName={propertyName} oldValue={change.oldValue} newValue={change.newValue} user={change.user}/>;
    }
}

function renderPromissoryNote(note: PromissoryNoteBankReportHistory) {
    const getNote = () => getPromissoryNote(note.id);

    return (
        <React.Fragment>
            <td>
                <Tooltip content={<Translate id="PROMISSORY_NOTE"/>}>
                    <FontAwesomeIcon icon={faPaperclip} />
                </Tooltip>
                </td>
            <td><FileLink getFile={getNote}>{note.fileName}</FileLink></td>
        </React.Fragment>
    );
}

function renderStatus(status: StatusBankReportHistoryReadModel) {
    return (
        <React.Fragment>
            <td>
                <Tooltip content={<Translate id="STATUS_FROM_BANK"/>}>
                    <FontAwesomeIcon icon={faServer} />
                </Tooltip>
                </td>
            <td>
                <Translation id={status.status} prefix="BANK_STATUS"/>
                {renderStatusComment(status.comment)}
            </td>
        </React.Fragment>
    );
}

function renderStatusComment(comment: string) {
    if (!comment) {
        return '';
    }

    return (
        <CopyToClipboardButton content={comment}>
            <Tooltip id="bank-replies-history-response-message-tooltip" content={comment}>
                <FontAwesomeIcon icon={faInfoCircle} className="bank-replies-status-comment"/>
            </Tooltip>
        </CopyToClipboardButton>
    );
}

export default BankRepliesHistory;
