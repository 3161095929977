import React from 'react';
import { BankStatus } from '../models/BankStatus';
import { Transaction } from '../../applications/applicant/models/Transaction';
import { Bank } from '../../applications/applicant/models/Bank';
import { beautifyFirstName, beautifyLastName } from './beautifyName';
import { ConsumerLoanApplicant } from '../../applications/applicant/models/ConsumerLoanApplicant';
import { Editor } from '@tinymce/tinymce-react';
import { ApplicantCommunicationModel } from '../models/ApplicantCommunicationModel';
import { User } from '../models/User';

export const fillTemplateContentWithData = (
    template: string,
    applicant: ConsumerLoanApplicant | undefined,
    incompleteFormLink: string,
    myApplicationUrl: string,
    signature: string,
    transactions: Transaction[],
    banks: Bank[]): string =>
    applicant
        ? replaceBankName(
            renderOffers(template, transactions, banks)
            .replace('[FirstName]', beautifyFirstName(applicant.firstName || ''))
            .replace('[LastName]', beautifyLastName(applicant.lastName || ''))
            .replace('[IncompleteFormLink]', incompleteFormLink)
            .replace('[MyApplicationUrl]', myApplicationUrl)
            .replace('[DocumentUploadLink]', `${myApplicationUrl}/upload`)
            .replace('[Signature]', signature),
                transactions, banks)
        : template;

const renderOffers = (template: string, transactions: Transaction[] = [], banks: Bank[] = []): string => {
    if (template.indexOf('bank-offer') < 0) {
        return template;
    }

    const grantedOrSent = transactions
        .filter((t) => t.bankStatus === BankStatus.Granted || t.bankStatus === BankStatus.Sent)
        .sort(compareOffers);

    if (grantedOrSent.length === 0) {
        return template;
    }

    const templateDoc = (new DOMParser()).parseFromString(template, 'text/html');
    const elements = templateDoc.getElementsByClassName('bank-offer');

    if (elements.length === 0 || !elements[0].parentElement) {
        return template;
    }

    const offerTemplate = elements[0].outerHTML;
    const numberToString = (n: number | undefined) => n === undefined || n === null ? '' : n.toLocaleString();

    elements[0].parentElement.innerHTML = grantedOrSent.map((t) => offerTemplate
        .replace('[BankName]', banks.filter((b) => b.id === t.bankId)[0].name.toUpperCase())
        .replace('[Amount]', numberToString(t.amount))
        .replace('[RepaymentPeriod]', numberToString(t.repaymentPeriod))
        .replace('[MonthlyCost]', numberToString(t.monthlyCost))
        .replace('[NominalInterest]', numberToString(t.nominalInterest))
        .replace('[EffectiveInterest]', numberToString(t.effectiveInterest))
        .replace('[SetupFee]', numberToString(t.setupFee))
        .replace('[InstallmentFee]', numberToString(t.installmentFee))
    ).join('');

    return templateDoc.documentElement.outerHTML;
};

const compareOffers = (t1: Transaction, t2: Transaction): number => {
    if (t1.amount === t2.amount) {
        return (t1.nominalInterest || 0) - (t2.nominalInterest || 0);
    }

    return (t2.amount || 0) - (t1.amount || 0);
};

const replaceBankName = (template: string, transactions: Transaction[], banks: Bank[]) => {
    if (transactions.length === 0 || template.indexOf('[BankName]') < 0) {
        return template;
    }

    const bankName = findBankName(transactions, banks);
    
    if (bankName === undefined) {
        return template;
    }

    return template.replace('[BankName]', bankName);
}

const findBankName = (transactions: Transaction[], banks: Bank[]) => {
    const transaction = transactions.find((t) => t.bankStatus && (t.bankStatus === BankStatus.GrantedSelected || t.bankStatus === BankStatus.GrantedAccepted || t.bankStatus === BankStatus.Sent || t.bankStatus === BankStatus.Paid));

    if (transaction === undefined) {
        return undefined;
    }

    return banks.find((b) => b.id === transaction.bankId)?.name;
}

export const renderEditor = (
    content: string,
    setContent: (c: string) => void,
    editorConfig: any) => {
    const onEditorChange = (c: string) => setContent(c);

    return (
        <Editor
            apiKey="9g9a6qujub52l8p4lxtqicol2mmztzn93l4pggag52ka7yfj"
            value={content}
            init={editorConfig}
            onEditorChange={onEditorChange}
        />
    );
};

export const fillSmsTemplateContentWithData = (
    template: string = '',
    signature: string = '',
    user?: User,
    applicant?: ApplicantCommunicationModel | null,
    incompleteFormLink: string = '',
    debtInformationUpdateLink: string = '',
    transactions: Transaction[] = [],
    banks: Bank[] = [],
    publicId: string = ''
) => replaceBankName(template, transactions, banks)
            .replace('[FirstName]', beautifyFirstName((applicant && applicant.firstName) || ''))
            .replace('[LastName]', beautifyLastName((applicant && applicant.lastName) || ''))
            .replace('[IncompleteFormLink]', incompleteFormLink)
            .replaceAll('[DebtInformationUpdate]', debtInformationUpdateLink)
            .replace('[Signature]', signature)
            .replace('[Email]', (user && user.email) || '')
            .replace('[Name]', (user && user.displayName) || '')
            .replace('[PhoneNumber]', (user && user.phoneNumber) || '')
            .replace('[PublicId]', publicId);

export const fillEmailTemplateContentWithData = (
    template: string = '',
    signature: string = '',
    user?: User,
    applicant?: ApplicantCommunicationModel | null,
    incompleteFormLink: string = '',
    myApplicationUrl: string = '',
    debtInformationUpdateLink: string = '',
    transactions: Transaction[] = [],
    banks: Bank[] = [],
    publicId: string = ''
) => replaceBankName(
        renderOffers(template, transactions, banks)
            .replace('[FirstName]', beautifyFirstName((applicant && applicant.firstName) || ''))
            .replace('[LastName]', beautifyLastName((applicant && applicant.lastName) || ''))
            .replace('[IncompleteFormLink]', incompleteFormLink)
            .replaceAll('[DebtInformationUpdate]', debtInformationUpdateLink)
            .replace('[MyApplicationUrl]', myApplicationUrl)
            .replace('[DocumentUploadLink]', `${myApplicationUrl}/upload`)
            .replace('[Signature]', signature)
            .replace('[ApplicantMobileNumber]', (applicant && applicant.mobileNumber) || '')
            .replaceAll('[Email]', (user && user.email) || '')
            .replaceAll('[PhoneNumber]', (user && user.phoneNumber) || '')
            .replace('[PublicId]', publicId),
    transactions, banks
);
