import React, {
	FunctionComponent,
	CSSProperties
} from 'react';

import CopyToClipboardButton from './CopyToClipboardButton';
import {beautifyLinks} from "../helpers/beautifyLinks";

interface TextClipperProps {
	className: string;
	content: string;
}

const TextClipper: FunctionComponent<TextClipperProps> = props => {
	const style = {
		cursor: 'default',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis'
	} as CSSProperties;
	
	let displayContent = props.content;
	if (displayContent.includes('\n')) {
		displayContent = displayContent.slice(0, displayContent.indexOf('\n'));
		displayContent += '(…)';
	}

	return (
		<CopyToClipboardButton content={props.content} className={props.className}>
			<div className="text-clipper" style={style} title={props.content}>
				{beautifyLinks(displayContent)}
			</div>
		</CopyToClipboardButton>
	);
};

export default TextClipper;
