import React, { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToggleSidebarActionCreator, ToggleSidebarActionProps } from '../actions/SidebarActionCreator';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isLimitedCaseWorker } from "../helpers/limitedCaseWorkerLogic";
import { UserProps } from "../interfaces/UserProps";
import { useLocation } from "react-router";
import {NAVIGATION_SIDEBAR_ID} from "./NavigationSidebar";

interface TopbarButtonsProps {
    logoSetting: string;
}

const TopbarButtons: FunctionComponent<TopbarButtonsProps & ToggleSidebarActionProps & UserProps> = (props) => {
    const location = useLocation();
    const navigateTo = getRoot(location.pathname);
    
    return (
        <div className="topbar-buttons">
            {!isLimitedCaseWorker(props) &&
                <span className="cursor-pointer" onClick={() => props.toggleSidebar(NAVIGATION_SIDEBAR_ID)}><FontAwesomeIcon icon={faBars} /></span>
            }
            <NavLink to={navigateTo}>
                <img
                    alt="Application logo"
                    className="logo"
                    src={`${process.env.REACT_APP_BASE_API_URL}/api/settings/logos/${props.logoSetting}`}
                />
            </NavLink>
        </div>
    );
};

const getRoot = (path: string) => {
    switch (true) {
        case path.includes('campaign'):
            return '/campaigns';
        case path.includes('applicant'):
            return '/applicants';
        case path.includes('marketing-list'):
            return '/marketing-lists';
        case path.includes('administration'):
            return '/administration';
        default:
            return '/';
    }
}

const mapStateToProps = (state: any) => ({
    ...state.userActionsReducer
});

const mapActionCreatorsToProps = (dispatch: any) => bindActionCreators(ToggleSidebarActionCreator, dispatch);

export default connect<UserProps, ToggleSidebarActionProps, TopbarButtonsProps, any>(mapStateToProps, mapActionCreatorsToProps)(TopbarButtons);
