import React, { FunctionComponent, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faBars } from "@fortawesome/free-solid-svg-icons";
import { Translate } from "react-localize-redux";
import classNames from 'classnames';
import { connect } from "react-redux";
import { UserProps } from "../../../common/interfaces/UserProps";
import { isAdmin } from "../../../common/helpers/isAdmin";

interface MarketingListMenuProps {
    onCopyClick?: () => void;
    onDeleteClick?: () => void;
    onResetUnansweredClick: () => void;
    onResetNotInterestedClick: () => void;
}

const MarketingListMenu: FunctionComponent<MarketingListMenuProps & UserProps> = (props) => {
    const [showSubstatusResetSubmenu, setShowSubstatusResetSubmenu] = useState<boolean>(false);
    const onMouseEnter = () => { setShowSubstatusResetSubmenu(true); };
    const onMouseLeave = () => { setShowSubstatusResetSubmenu(false); };

    const statusResetSubmenu = !isAdmin(props) ? null : (
        <>
            <Dropdown.Divider />
            <div className={classNames('dropdown-no-indicator dropdown-submenu dropleft')}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                <div className="dropdown-toggle">
                    <span style={{ marginLeft: '-15px', marginRight: '7px' }}>
                        <FontAwesomeIcon icon={faAngleLeft} />
                    </span>
                    <Translate id="RESET_STATUS" />
                </div>
                <div className={classNames('dropdown-menu', { show: showSubstatusResetSubmenu })}>
                    <div className="dropdown-arrow-left" />
                    <Dropdown.Item onClick={props.onResetUnansweredClick}>
                        <Translate id="RESET_STATUS_UNANSWERED" />
                    </Dropdown.Item>
                    <Dropdown.Item onClick={props.onResetNotInterestedClick}>
                        <Translate id="RESET_STATUS_NOT_INTERESTED" />
                    </Dropdown.Item>
                </div>
            </div>
        </>
    );

    return (
        <Dropdown className="actions" drop="down" align="end">
            <Dropdown.Toggle id="application-menu" className="btn-white-bg" style={{ padding: '3px 12px' }}>
                <FontAwesomeIcon icon={faBars} />
            </Dropdown.Toggle>
            <div className="dropdown-arrow-down" />
            <Dropdown.Menu>
                <Dropdown.Item key="copy-marketing-list" onClick={props.onCopyClick}>
                    <Translate id="COPY" />
                </Dropdown.Item>
                <Dropdown.Item key="delete-marketing-list" onClick={props.onDeleteClick} style={{ color: '#F05B59' }}>
                    <Translate id="DELETE" />
                </Dropdown.Item>
                {statusResetSubmenu}
            </Dropdown.Menu>
        </Dropdown>
    );
}

const mapStateToProps = (state: any) => ({ ...state.userActionsReducer })

export default connect<UserProps, {}, MarketingListMenuProps, any>(mapStateToProps)(MarketingListMenu);