import { ToggleSidebarAction, TOGGLE_SIDEBAR } from '../actions/SidebarActionCreator';

export interface SidebarState {
    sidebarExpanded?: string[];
}

export function sidebarReducer(state: any, action: ToggleSidebarAction) {
    state = state || { sidebarExpanded: [] };

    if (action.type === TOGGLE_SIDEBAR) {
        if (state.sidebarExpanded.includes(action.id)) {
            state.sidebarExpanded = state.sidebarExpanded.filter(id => id !== action.id);
        } else {
            state.sidebarExpanded = state.sidebarExpanded.concat(action.id);
        }
        return {
            ...state
        };
    }

    return state;
}
